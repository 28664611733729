import React, { useCallback, useEffect, useState } from 'react'
import { Auth } from '@aws-amplify/auth'
import { ThemeProvider } from '@mui/material/styles'
import { BrowserRouter } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { onChangeLanguage, refreshAccount, setLoggingin } from './actions'
import { sendNotification } from './functions/notifications'
import '../src/containers/Home/Home.css'
import Routes from './Routes'
import NavBar from './components/Nav/NavBar'
import Footer from './components/Footer/Footer'
import PendingSubscriptionPaymentPopUp from './components/PendingSubscriptionPaymentPopUp'
import './App.css'
import './components/ResponsivePlayer.css'
import { darkTheme } from './styles'
import CookiesBanner from './containers/CookiesBanner'
import 'react-notifications-component/dist/theme.css'
import { getCountryOfUser, updateTTLUserId } from './services'
import { CssBaseline } from '@mui/material'
import { detectLanguage } from './functions/detectLanguage'
import { IReducer } from './interface'
import VideoModal from './components/VideoModal'
import { ReactNotifications } from 'react-notifications-component'
import { updateFBCookies, updateGACookies } from './utils/cookies'

interface AppContextInterface {
  isSmallScreen: boolean
  isFromUSCountry: boolean
}

export const ConfigContext = React.createContext<AppContextInterface>({
  isSmallScreen: false,
  isFromUSCountry: false
})

const App = () => {
  const [cookies, setCookie] = useCookies()
  const language = useSelector((state: IReducer) => state.changeLanguage)
  const dispatch = useDispatch()
  const [isSmallScreen, setIsSmallScreen] = useState(window.matchMedia('(max-width: 1200px)').matches)

  const configValue = {
    isSmallScreen,
    isFromUSCountry: cookies.country_code === 'us'
  }

  const setCookieMC = () => {
    const MCCookies = window.location.href.split('?')[1]
    if (MCCookies) {
      // 5256000 two Months
      setCookie('MCSalesForce', MCCookies, { path: '/', maxAge: 5256000 })
    }
  }

  const initialAddNotification = useCallback((event: CustomEventInit) => {
    sendNotification(event.detail)
  }, [])

  const refreshUser = useCallback(async () => {
    // Disable the login button while the user is being refreshed, which happens when the user access /login directly
    dispatch(setLoggingin(true))
    let username
    try {
      const userInfo = await Auth.currentAuthenticatedUser()
      username = userInfo.username
    } catch (error) {
      username = null
    }
    if (username) {
      try {
        await dispatch(refreshAccount(username, setCookie, cookies.popupDay))
      } catch (error) {
        console.error('refreshUser - ', error)
      }
    } else {
      dispatch(setLoggingin(false))
      if (cookies.temporary_user_id) {
        window.dispatchEvent(new CustomEvent('getCartWithoutLogin'))
      }
    }
  }, [dispatch, cookies.temporary_user_id, setCookie])

  useEffect(() => {
    if (cookies.country_code === undefined) {
      getCountryOfUser()
        .then((result) => {
          setCookie('country_code', result, { path: '/' })
        })
        .catch(() => {
          setCookie('country_code', 'us', { path: '/' })
        })
    }
  }, [cookies.country_code, setCookie])

  useEffect(() => {
    // Initialize cookies
    if (cookies.marketingCookies) {
      updateFBCookies(true)
    }
    if (cookies.analysisCookies) {
      updateGACookies(true)
    }

    // Refresh user session
    refreshUser()

    // Check if the user is accessing the website from a mobile device
    const handler = (e: any) => {
      setIsSmallScreen(e.matches)
    }
    setCookieMC()
    window.matchMedia('(max-width: 1200px)').addListener(handler)
  }, [])

  useEffect(() => {
    if (cookies.temporary_user_id === undefined) {
      const { temporary_user_id, date } = updateTTLUserId()
      setCookie('temporary_user_id', temporary_user_id, { expires: date, path: '/' })
    }
  }, [cookies.temporary_user_id, setCookie])

  useEffect(() => {
    if (cookies.language === undefined) {
      const result = detectLanguage()
      setCookie('language', result.lang, { path: '/' })
    } else if (language.lang !== cookies.language) {
      dispatch(onChangeLanguage(cookies.language))
    }
  }, [cookies.language, setCookie, dispatch, language.lang])

  useEffect(() => {
    window.addEventListener('sendNotification', initialAddNotification)
    window.addEventListener('onReadyMicrofrontendCart', refreshUser)
    return () => {
      window.removeEventListener('sendNotification', initialAddNotification)
      window.removeEventListener('onReadyMicrofrontendCart', refreshUser)
    }
  }, [initialAddNotification, refreshUser, dispatch])

  return (
    <BrowserRouter>
      <ThemeProvider theme={darkTheme}>
        <ConfigContext.Provider value={configValue}>
          <CssBaseline>
            <div className='App'>
              <ReactNotifications />
              <PendingSubscriptionPaymentPopUp />
              <NavBar />
              <Routes />
              <Footer />
              <CookiesBanner />
              <VideoModal />
            </div>
          </CssBaseline>
        </ConfigContext.Provider>
      </ThemeProvider>
    </BrowserRouter>
  )
}

export default App
