export const BASE_URL = process.env.REACT_APP_ENDPOINT
export const BASE_URL_STRIPE = `${BASE_URL}/stripe`
export const BASE_URL_GEMVISION = `${BASE_URL}/gemvision`
export const BASE_URL_STULLER = `${BASE_URL}/stuller`
export const BASE_URL_GRAPHQL = `${BASE_URL}/graphql`
export const DEALERS_ENDPOINT = 'https://4nenooejwc.execute-api.us-east-1.amazonaws.com/Prod'
export const DEALERS_LAMBDA_URL = DEALERS_ENDPOINT + '/dealers/public'
export const COUNTRIES_LAMDA_URL = DEALERS_ENDPOINT + '/country'
export const MilliSecondsInAWeek = 604800000
export const MatrixGoldIcon = 'https://gemvision.com/Images/MatrixGold/matrixgold_dark_icon.svg'
export const CounterSketchIcon = 'https://gemvision.com/Images/CounterSketch/sales_logo_countersketch.svg'
export const ClayooIcon = 'https://gemvision.com/Images/Clayoo/sales_logo_clayoo.svg'
export const RhinoNestIcon = {
  src: 'https://meteor.stullercloud.com/das/98539856?fmt=smart-alpha',
  width: 20,
  height: 20
}
export const GVIcon = 'https://gemvision.com/Images/gemvision_logo_white.svg'
export const GVIconG = 'https://gemvision.com/Images/gemvision_logo_g_white.svg'
export const WindowsIconSrc = 'https://gemvision.com/Images/CounterSketch/windows_icon.svg'
export const MacOSIcon = 'https://gemvision.com/Images/CounterSketch/mac_os_icon.svg'
type environmentType = 'local' | 'dev' | 'prod' | 'alpha'
const env: environmentType = document.location.hostname.includes('localhost')
  ? 'local'
  : process.env.REACT_APP_current_stage === 'prod'
    ? 'prod'
    : process.env.REACT_APP_current_stage === 'dev'
      ? 'dev'
      : 'alpha'
export class config {
  public static get REGION(): string {
    return 'us-east-1'
  }
  //Stuller Cognito IDs
  public static get USER_POOL_ID(): string {
    return 'us-east-1_XSISJT4rm'
  }
  public static get APP_CLIENT_ID(): string {
    return '4se98otsgp0t66i614g8ahrufn'
  }
  public static get DOMAIN(): string {
    return DOMAIN_OBJ[env]
  }
  public static get GOOGLE_ANALYTICS_4_TRAKING_ID(): string {
    return GOOGLE_ANALYTICS_4_TRAKING_ID_OBJ[env]
  }
  public static get GOOGLE_TAG_MANAGER(): string {
    return GOOGLE_TAG_MANGER_OBJ[env]
  }
  public static get MATRIXGOLD_DEMO_GEMVISION(): string {
    return MATRIXGOLD_DEMO_GEMVISION_OBJ[env]
  }
  public static get MATRIXGOLD_LIVE_DEMO_GEMVISION(): string {
    return MATRIXGOLD_LIVE_DEMO_GEMVISION_OBJ[env]
  }
  public static get COUNTERSKETCH_DEMO_GEMVISION(): string {
    return COUNTERSKETCH_DEMO_GEMVISION_OBJ[env]
  }
  public static get COUNTERSKETCH_LIVE_DEMO_GEMVISION(): string {
    return COUNTERSKETCH_LIVE_DEMO_GEMVISION_OBJ[env]
  }
  public static get COUNTERSKETCH_TRIAL_GEMVISION(): string {
    return COUNTERSKETCH_TRIAL_GEMVISION_OBJ[env]
  }
  public static get COUNTERSKETCH_WEBINAR_GEMVISION(): string {
    return COUNTERSKETCH_WEBINAR_GEMVISION_OBJ[env]
  }
  public static get MATRIXGOLD_WEBINAR_GEMVISION(): string {
    return MATRIXGOLD_WEBINAR_GEMVISION_OBJ[env]
  }
}
const DOMAIN_OBJ = {
  local: 'localhost',
  dev: 'dev-web.gemvision.com',
  alpha: 'alpha-web.gemvision.com',
  prod: 'gemvision.com'
}
const GOOGLE_ANALYTICS_4_TRAKING_ID_OBJ = {
  local: 'G-1SD89TJ089',
  alpha: 'G-1SD89TJ089',
  dev: 'G-1SD89TJ089',
  prod: 'G-FJKRRLFFFL'
}
const GOOGLE_TAG_MANGER_OBJ = {
  local: 'GTM-KRW9VM8',
  alpha: 'GTM-KRW9VM8',
  dev: 'GTM-KRW9VM8',
  prod: 'GTM-T63B5KD'
}
const MATRIXGOLD_DEMO_GEMVISION_OBJ = {
  local: 'http://localhost:3020/matrixgold-demo',
  alpha: 'https://alpha-demo.gemvision.com/matrixgold-demo',
  dev: 'https://dev-demo.gemvision.com/matrixgold-demo',
  prod: 'https://demo.gemvision.com/matrixgold-demo'
}
const COUNTERSKETCH_DEMO_GEMVISION_OBJ = {
  local: 'http://localhost:3020/countersketch-demo',
  alpha: 'https://alpha-demo.gemvision.com/countersketch-demo',
  dev: 'https://dev-demo.gemvision.com/countersketch-demo',
  prod: 'https://demo.gemvision.com/countersketch-demo'
}
const MATRIXGOLD_LIVE_DEMO_GEMVISION_OBJ = {
  local: 'http://localhost:3050/matrixgold-demo',
  alpha: 'https://alpha-livedemo.gemvision.com/matrixgold-demo',
  dev: 'https://dev-livedemo.gemvision.com/matrixgold-demo',
  prod: 'https://livedemo.gemvision.com/matrixgold-demo'
}
const COUNTERSKETCH_LIVE_DEMO_GEMVISION_OBJ = {
  local: 'http://localhost:3050/countersketch-demo',
  alpha: 'https://alpha-livedemo.gemvision.com/countersketch-demo',
  dev: 'https://dev-livedemo.gemvision.com/countersketch-demo',
  prod: 'https://livedemo.gemvision.com/countersketch-demo'
}
const COUNTERSKETCH_TRIAL_GEMVISION_OBJ = {
  local: 'http://localhost:3020/countersketch-trial',
  alpha: 'https://alpha-demo.gemvision.com/countersketch-trial',
  dev: 'https://dev-demo.gemvision.com/countersketch-trial',
  prod: 'https://demo.gemvision.com/countersketch-trial'
}
const COUNTERSKETCH_WEBINAR_GEMVISION_OBJ = {
  local: 'http://localhost:3040/countersketch-webinar',
  alpha: 'https://alpha-webinars.gemvision.com/countersketch-webinar',
  dev: 'https://dev-webinars.gemvision.com/countersketch-webinar',
  prod: 'https://webinars.gemvision.com/countersketch-webinar'
}
const MATRIXGOLD_WEBINAR_GEMVISION_OBJ = {
  local: 'http://localhost:3040/matrixgold-webinar',
  alpha: 'https://alpha-webinars.gemvision.com/matrixgold-webinar',
  dev: 'https://dev-webinars.gemvision.com/matrixgold-webinar',
  prod: 'https://webinars.gemvision.com/matrixgold-webinar'
}
